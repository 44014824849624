<template>
  <div class="step">
    <p class="step_title font-14">请输入您绑定的手机号</p>
    <div class="step_bd">
      <span class="font-14 color-fff">手机号</span>
      <input v-model="phone" class="font-16 w-368" type="number" placeholder="请输入手机号" />
      <div class="mt-20">
        <span class="font-14 color-fff">验证码</span>
        <div class="inline code" :class="authCode.length === 6 ? 'codeConfirm' : ''">
          <input v-model="authCode" class="font-16" type="number" placeholder="请输入验证码" />
          <div class="step_bd_pro flex-center" v-if="phoneNumPro">
            <span class="caution color-e6081f font-14">{{loginfailmsg}}</span>
          </div>
        </div>
        <button
          type="button"
          class="w-102 ml-10 color-fff font-14"
          :class="sendAuthCode ? 'o-06' : ''"
          :disabled="sendAuthCode"
          @click="getAuthCode"
        >{{sendAuthCode ? `${authTime}s后重发` : '获取验证码'}}</button>
      </div>
      <button type="button" class="step_nextbtn color-fff font-14" @click="nextStep">下一步</button>
    </div>
  </div>
</template>

<script>
import { loginApi } from '@/axios'

let authTimeTimer = null

export default {
  name: 'Step1',
  data () {
    return {
      phone: '',
      authCode: '',
      loginfailmsg: '',
      authTime: 60,
      sendAuthCode: false,
      phoneNumPro: false
    }
  },
  methods: {
    // 验证码发送
    getAuthCode () {
      let jud = /^1\d{10}$/
      if (this.phone.match(jud) === null) {
        this.loginfailmsg = '请输入正确的手机号码'
        this.phoneNumPro = true
        return
      }
      let url = loginApi + 'admin/user/sendCode'
      let data = {
        id: '',
        phone: this.phone
      }
      this.$https.post(url, data).then(({code, msg}) => {
        if (code !== 200) {
          this.loginfailmsg = msg || '验证码获取失败'
          this.phoneNumPro = true
          return
        }
        this.phoneNumPro = false
        this.sendAuthCode = true
        this.authTime = 60
        authTimeTimer = setInterval(() => {
          this.authTime -= 1
          if (this.authTime <= 0) {
            this.sendAuthCode = false
            clearInterval(authTimeTimer)
          }
        }, 1000)
      })
    },
    nextStep () {
      let jud = /^1\d{10}$/
      if (this.phone.match(jud) === null || !this.phone) {
        this.loginfailmsg = '请输入正确的手机号码'
        this.phoneNumPro = true
        return
      }
      this.$router.push({
        name: 'step2',
        params: {
          phoneNumber: this.phone,
          authCode: this.authCode
        }
      })
    }
  },
  destroyed () {
    clearInterval(authTimeTimer)
  }
}
</script>

<style lang="less" scoped>
button {
  border: 0;
  padding: 0;
  height: 40px;
  outline: none;
  border-radius: 4px;
  background-image: linear-gradient(270deg, #01D18A 0%, #2483FF 100%);
  cursor: pointer;
}
input {
  height: 40px;
  margin-left: 12px;
  padding-left: 12px;
  background: #FFF;
  border-radius: 4px;
  border: 1px solid #D8D8D8;
  outline: none;
  box-sizing: border-box;
}
.w-368 {
  width: 368px;
}
.w-102 {
  width: 102px;
}
.ml-10 {
  margin-left: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.o-06 {
  opacity: 0.6;
}
.step{
  position: relative;
  padding: 0 30px;
  height: 280px;
  .step_title{
    margin: 30px 0 0;
    color: #FFF;
  }
  .step_bd{
    margin-top: 30px;
    text-align: center;
  }
  .code {
    position: relative;
    width: 256px;
    margin-left: 12px;
    input {
      width: 100%;
      margin: 0;
      padding-right: 30px;
      box-sizing: border-box;
    }
  }
  .codeConfirm {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 12px;
      width: 14px;
      height: 14px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%);
      background-image: url('/assets/correct.png');
    }
  }
  .step_bd_pro {
    position: absolute;
    bottom: -37px;
    left: 0;
    .caution {
      position: relative;
      padding-left: 23px;
      &::before {
        position: absolute;
        content: '';
        width: 16px;
        height: 16px;
        left: 0px;
        top: 50%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        transform: translateY(-50%);
        background-image: url('/assets/caution.png');
      }
    }
  }
  .step_nextbtn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    width: 85px;
  }
  .inline {
    display: inline-block;
  }
}
</style>
